<template>
  <Footer/>
</template>
<script>

import { Footer } from 'global-components';
import { EmbedMixin } from 'global-mixin';

export default {
  name: 'footer-page',
  components: { Footer },
  mixins: [EmbedMixin],
  data() {
    return {
      height: 0,
      heightMonitor: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.monitorHeight();
      this.checkHeight();
      window.removeEventListener('resize', this.checkHeight);
      window.addEventListener('resize', this.checkHeight);
    });
  },
  beforeDestroy() {
    this.heightMonitor.disconnect();
  },
  methods: {
    monitorHeight() {
      this.heightMonitor = new MutationObserver(this.checkHeight);
      this.heightMonitor.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true
      });
      const _this = this;
      document.documentElement.addEventListener('DOMAttrModified', function (e) {
        if (e.target.id === 'onetrust-banner-sdk' && e.attrName === 'style') {
          _this.monitorHeight(_this);
        }
      }, false);
    },
    checkHeight() {
      const onetrustBannerSdk = document.getElementById('onetrust-banner-sdk');
      const sideFooter = document.getElementsByClassName('site-footer')[0];
      const clientHeight = onetrustBannerSdk !== null
        ? onetrustBannerSdk.scrollHeight + 2 > sideFooter.offsetHeight ? onetrustBannerSdk.scrollHeight + 2 : sideFooter.offsetHeight
        : sideFooter.offsetHeight;
      if (clientHeight !== this.height) {
        this.height = clientHeight;
        console.log(`New Footer Height : ${this.height}`);
        window.top.postMessage({ 'footer-height': this.height }, '*');

        if (onetrustBannerSdk !== null) {
          const privacyCookieLinks = onetrustBannerSdk.querySelectorAll('[href]');
          if (privacyCookieLinks.length > 0) {
            privacyCookieLinks.forEach(function (link) {
              link.setAttribute('target', '_parent');
            });
          }
        }
      }
    }
  },
  embed(head) {
    head.add(`
    <style type="text/css">
      .site-footer {
        left: 0;
        bottom: 0;
        position: fixed;
      }
      #onetrust-banner-sdk.otFlat {
        max-height: 100% !important;
      }
    </style>`);
  }
};
</script>
